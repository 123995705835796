:root {
  /* Break Points */
  --fs-media-m: 768px;
  --fs-media-l: 1024px;
  --fs-media-xl: 1400px;
  --fs-media-xxl: 1600px;

  /* Colors */
  --fs-color-dark-gray: #3D3C35;
  --fs-color-medium-gray: #41545D;
  --fs-color-light-gray: #FFFFFF;
  --fs-color-primary: #3163E7;
  /* --fs-color-primary-light: #FFDAD3; */
  --fs-color-secondary: #FDFFBA;
  /* --fs-color-secondary-light: #9CABBA; */
  --fs-color-grayshadow: rgba(65, 84, 93, 0.5);

}