/* atkinson regular */

@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url("./../assets/fonts/atkinson_hyperlegible_regular_102.eot");
  src: url("./../assets/fonts/atkinson_hyperlegible_regular_102.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/atkinson_hyperlegible_regular_102a.woff2") format("woff2"),
    url("./../assets/fonts/atkinson_hyperlegible_regular_102.woff") format("woff"),
    url("./../assets/fonts/atkinson_hyperlegible_regular_102.ttf") format("truetype"),
    url("./../assets/fonts/atkinson_hyperlegible_regular_102.svg#atkinsonregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* atkinson italic */

@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url("./../assets/fonts/atkinson_hyperlegible_italic_102.eot");
  src: url("./../assets/fonts/atkinson_hyperlegible_italic_102.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/atkinson_hyperlegible_italic_102a.woff2") format("woff2"),
    url("./../assets/fonts/atkinson_hyperlegible_italic_102.woff") format("woff"),
    url("./../assets/fonts/atkinson_hyperlegible_italic_102.ttf") format("truetype"),
    url("./../assets/fonts/atkinson_hyperlegible_italic_102.svg#atkinsonitalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
}

/* atkinson bold */

@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url("./../assets/fonts/atkinson_hyperlegible_bold_102.eot");
  src: url("./../assets/fonts/atkinson_hyperlegible_bold_102.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/atkinson_hyperlegible_bold_102a.woff2") format("woff2"),
    url("./../assets/fonts/atkinson_hyperlegible_bold_102.woff") format("woff"),
    url("./../assets/fonts/atkinson_hyperlegible_bold_102.ttf") format("truetype"),
    url("./../assets/fonts/atkinson_hyperlegible_bold_102.svg#atkinsonbold")
      format("svg");
  font-weight: bold;
  font-style: normal;
}

/* atkinson bold */

@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url("./../assets/fonts/atkinson_hyperlegible_bolditalic_102.eot");
  src: url("./../assets/fonts/atkinson_hyperlegible_bolditalic_102.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/atkinson_hyperlegible_bolditalic_102a.woff2") format("woff2"),
    url("./../assets/fonts/atkinson_hyperlegible_bolditalic_102.woff") format("woff"),
    url("./../assets/fonts/atkinson_hyperlegible_bolditalic_102.ttf") format("truetype"),
    url("./../assets/fonts/atkinson_hyperlegible_bolditalic_102.svg#atkinsonbolditalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
}