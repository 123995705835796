@import "./_theme.css";
@import "./_layout.css";
@import "./_header.css";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 50%;
  /* 10px/16px = 62.5% -> 1rem = 10px */
}

@media (min-width: 768px) {
  html {
    font-size: 62.5%;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: var(--fs-font-display);
}

a {
  text-decoration: none;
}


/* Header */
.header {
  display: flex;
  justify-content: flex-end;
  height: 150px;
  padding-top: 4rem;
  padding-right: 5rem;
  background: url("./../assets/images/background_Banner.png");
}

.header-title {
  font-family: var(--fs-font-display);
  font-style: normal;
  font-weight: normal;
  font-size: 4rem;
  line-height: normal;
  /* line-height: 5.2rem; */
  text-align: center;
  /* letter-spacing: 0.2em; */
  color: var(--fs-color-font-light);
}

/* Header */


/* Footer */
.footer {
  background: url("./../assets/images/background_Banner2.png");
  display: flex;
  justify-content: center;
  height: 150px;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .footer {
    margin-top: 8rem;
  }
}

/* Footer */


/* Section */
.section-header {
  display: flex;
  justify-content: flex-end;
  padding: 6rem 3rem 3rem 0rem;
}

.section-title {

  /* font-style: italic; */
  font-weight: normal;
  font-size: 4rem;
  line-height: 6rem;
  /* text-align: right; */
  /* letter-spacing: 0.4em; */
  /* text-transform: lowercase; */
  /* margin-bottom: 2rem; */
}

.paragraph {
  margin-bottom: 1.7rem;
}

.paragraph-big-space {
  margin-bottom: 6rem;
}

.paragraph:last-of-type {
  margin-bottom: 0;
}

.image {
  mix-blend-mode: normal;

  box-shadow: var(--fs-shadow-left-down);

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 6rem;
  margin-left: 3rem;

  width: calc(100% - 3rem);
}

.image-img {
  width: 100%;
}

/* Fading animation */
/* .fade {
  animation-name: fade;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
}

@keyframes fade {
  from {
    opacity: 1;
    scale: 0.9
  }

  to {
    opacity: 1
  }
} */

.image-vita {
  width: calc(100% - 3rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-vita-img {
  width: 90%;
}

/* Color */

.background-primary {
  background-color: var(--fs-color-primary);
}

.background-primary-light {
  background-color: var(--fs-color-primary-light);
}

.background-secondary {
  background-color: var(--fs-color-secondary);
}

.background-secondary-light {
  background-color: var(--fs-color-secondary-light);
}

.background-gray-light {
  background-color: var(--fs-color-light-gray);
  opacity: 0.5;
}

.background-img-pink {
  background: url("./../assets/images/background_Quadrat.png");
  background-position: top 6rem left 5rem;
}


/* Shadow */
.shadow-down {
  box-shadow: var(--fs-shadow-down);
  margin-bottom: 3rem;
}

/* Spacer */

.mb-m {
  margin-bottom: 3rem;
}

.ml-m {
  margin-left: 3rem;
}

.mr-m {
  margin-right: 3rem;
}

.mt-m {
  margin-top: 3rem;
}

/* Typography */

.section-title,
.section-subtitle,
.subtitle,
.francis-mailto-subtitle {
  font-family: var(--fs-font-display);
}

.subtitle {
  font-size: 2.4rem;
  line-height: 6rem;
  letter-spacing: 0.45em;
  text-transform: uppercase;
  text-align: center;
}

.subtitle-shadow {
  height: 1.9rem;
  background-color: var(--fs-color-shadow);
  margin-top: -3rem;
}

.subtitle-shadow-light {
  height: 1.9rem;
  background-color: var(--fs-color-shadow-light);
  margin-top: -3rem;
}

.text {
  font-size: 2rem;
   line-height: 2.8rem;
}

.text-small {
  font-size: 1.8rem;
  /* line-height: 3rem; */
}

.text-color-light {
  color: var(--fs-color-font-light);
}

.text-color-primary {
  color: var(--fs-color-font-primary);
}

.text-color-secondary {
  color: var(--fs-color-font-secondary);
}

.text-color-dark {
  color: var(--fs-color-dark-gray);
}



/* Helper */

.contact {
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.contact a {
  line-height: 1.5;
}

.francis-mailto {
  margin-top: 4rem;
  margin-bottom: 2rem;

  width: 100%;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 55rem;
}

.francis-mailto-text {
  align-content: center;
  margin-right: 3rem;
}

/* box*/
.box-mailto {
  position: relative;
  width: 250px;
  /* margin-right: 1rem; */
  /* margin-bottom: 1rem; */
}

.box-mailto-background {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 26rem;
}

.box-mailto-content {
  position: relative;
}


.box-bio {
  width: 100%;
  position: relative;
  margin-bottom: 8%;
}

.box-bio-background {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 95%;
  height: 100%;
  background: url("./../assets/images/background_Quadrat.png");
}

.box-bio-content {
  position: relative;
  width: 95%;
  height: 46rem;
  align-content: end;
  padding-left: 3rem;
  padding-bottom: 4rem;
}

.box-bio-titel {
  font-size: 2rem;
}

.box-bio-text {
  font-size: 4rem;
  line-height: normal;
}

.box-img {
  width: 100%;
  position: relative;
  margin-bottom: 4rem;
}

.box-img-background {
  position: absolute;
  top: 7%;
  left: 3%;
  width: 97%;
  height: 100%;
  background: url("./../assets/images/background_Quer.png");
}

.box-img-content {
  position: relative;
  width: 95%;
  height: 100%;
}

.box-portfolio {
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
}

.box-portfolio-background {
  height: 100%;
  width: 98%;
  position: absolute;
  top: 8%;
  left: 2%;
  background: url("./../assets/images/background_Button2.png");
}

.box-portfolio-content {
  /* height: 20rem; */
  width: 98%;
  /* position: relative; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-content: center; */
}

.box-francis {
  width: 100%;
  position: relative;
  /* margin-right: 3rem; */
  margin-top: 6rem;
}

.box-francis-background {
  width: 100%;
  height: 90%;
  position: absolute;
  top: -6rem;
  /* left: 3rem; */
  background: url("./../assets/images/background_Quer2.png");
}

.box-francis-content {
  position: relative;
  margin-left: 25%;
  /* width: 490px;
  height: 460px;
  align-content: end;
  padding-left: 3rem;
  padding-bottom: 4rem; */
}

.box-francis-img {
  width: 90%;
  /* filter: drop-shadow(var(--fs-shadow-left-up));
  margin-top: 3rem;
  margin-bottom: 3rem; */
}

/* short bio */


/* francis-portrait */
.francis-portrait {}





/*
.francis-portrait-subtitle {
  margin-top: 5rem;
  max-width: 55rem;
}



.francis-mailto-subtitle {
  font-size: 2.5rem;
  letter-spacing: 0.45em;
  text-transform: uppercase;
  text-align: center;
}

.francis-portrait-subtitle-text {
  margin-top: 4rem;
  margin-bottom: 6rem;

  font-size: 2rem;
  line-height: 4rem;
}

@media (min-width: 1024px) {
  .francis-portrait-subtitle-text {
    max-width: 55rem;
    margin-top: 2rem;

    font-size: 1.5rem;
    line-height: 3rem;
  }
}

.francis-title {
  font-family: var(--fs-font-display);
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  line-height: 5.2rem;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: var(--fs-color-font-light);

  margin-bottom: 3rem;
}

@media (min-width: 1024px) {
  .francis-title {
    margin-bottom: 12rem;
  }
} */
/* francis-portrait */