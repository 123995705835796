/* layout main */
.layout-content {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 6rem;
  padding-bottom: 6rem;

  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .layout-content {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1024px) {
  .layout-content {
    padding-top: 16rem;
    padding-bottom: 8rem;
  }
}
/* layout main */

/* layout impressum */
.layout-index {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 6rem;
  padding-bottom: 6rem;

  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .layout-index {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.layout-index-content {
  max-width: 1024px;
  display: flex;
  flex-direction: column;
}
/* layout impressum */


/* Helper */
.hidde-on-small {
  display: none;
}

@media (min-width: 768px) {
  .hidde-on-small {
    display: block;
  }
}

.hidde-on-medium {
  display: block;
}

@media (min-width: 768px) {
  .hidde-on-medium {
    display: none;
  }
}
/* Helper */

/* Grid */
.layout-grid {
  max-width: 1520px;
  display: grid;
}

.layout-grid-column-left,
.layout-grid-column-right {
  display: contents;
}
.layout-column-center{
   justify-content: center;
}
@media (min-width: 768px) {
  .layout-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;

    width: 98vw;
  }

  .layout-grid-column-left,
  .layout-grid-column-right {
    display: contents;
  }
}

@media (min-width: 1024px) {
  .layout-grid {
    column-gap: 7rem;
  }

  .layout-grid-column-left {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }

  .layout-grid-column-right {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
}

/* Grid */

.layout-footer {
  max-width: 1520px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* align-items: center; */
   padding-top: 7rem;
  /* padding-bottom: 5rem; */
}

/* Header */

.layout-francis-title {
  grid-row-start: 1;
  grid-row-end: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.layout-francis-image {
  grid-row-start: 2;
  grid-row-end: 3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layout-francis-description {
  grid-row-start: 3;
  grid-row-end: 4;
}

.layout-francis-subtitle {
  grid-row-start: 4;
  grid-row-end: 5;

  display: flex;
  justify-content: center;
}

.layout-francis-mailto {
  grid-row-start: 5;
  grid-row-end: 6;

  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .layout-francis-image {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .layout-francis-title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .layout-francis-description {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .layout-francis-subtitle {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .layout-francis-mailto {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

/* left & right */

.layout-subtitle {
  grid-row-start: 1;
  grid-row-end: 2;

  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 3rem;
}

.layout-image {
  grid-row-start: 2;
  grid-row-end: 3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.layout-description {
  grid-row-start: 3;
  grid-row-end: 4;
}

@media (min-width: 768px) {
  .layout-subtitle {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-bottom: 3rem;
  }

  .layout-image {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 60%; */
  }

  .layout-description {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }

}

@media (min-width: 1400px) {
  .layout-grid {
    column-gap: 6rem;
  }
}

@media (min-width: 1600px) {
  .layout-grid {
    column-gap: 12rem;
  }
}


/* vita */

.  {
  justify-content: start;
}

.layout-vita-image {
  flex-grow: 1;
}