@import "./_fonts.css";
@import "./_variables.css";

:root {
  /* Colors */

  --fs-color-link-dark: var(--fs-color-dark-gray);
  --fs-color-link-light: var(--fs-color-light-gray);
  --fs-color-link-primary: var(--fs-color-primary);
  --fs-color-link-secondary: var(--fs-color-secondary);

  --fs-color-font-dark: var(--fs-color-dark-gray);
  --fs-color-font-light: var(--fs-color-light-gray);
  --fs-color-font-primary: var(--fs-color-primary);
  --fs-color-font-secondary: var(--fs-color-secondary);

  --fs-color-shadow: var(--fs-color-grayshadow);
  --fs-color-shadow-light: var(--fs-color-primary-light);

  /* Box Shadows */

  --fs-shadow-down: 0 3rem 0 var(--fs-color-shadow);
  --fs-shadow-left-up: -3rem -3rem 0 var(--fs-color-shadow);
  --fs-shadow-left-down: -3rem 3rem 0 var(--fs-color-shadow);

  --fs-button-shadow: -1rem 1rem 0 var(--fs-color-shadow);
  --fs-button-shadow-small: -0.5rem 0.5rem 0 var(--fs-color-shadow);



  /* Typrography */

  --fs-font-primary: "Open Sans", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif;
  --fs-font-display: "Atkinson Hyperlegible", "Open Sans", system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    "Helvetica Neue", sans-serif;

}