/* link-primary-medium */
.link-primary-medium {
  /* font-style: italic; */
  font-size: 2rem;
  /* text-decoration-line: none; */
  /* line-height: 4rem; */
  /* letter-spacing: 0.1em; */
  text-decoration-line: underline;
  color: var(--fs-color-link-primary);
}

.link-primary-medium:hover {
  color: var(--fs-color-link-dark);
}

/* link-primary-medium */


.link-dark-small {
  font-size: 1.8rem;
  /* line-height: 3rem; */
  text-decoration-line: underline;
  color: var(--fs-color-link-primary);
}

.link-dark-small:hover {
  color: var(--fs-color-link-dark);
}



.link-secondary-small {
  font-size: 1.5rem;
  line-height: 3rem;
  text-decoration-line: underline;
  color: var(--fs-color-link-light);

  font-style: italic;
  letter-spacing: 0.2em;
  text-decoration-line: underline;
  color: var(--fs-color-link-secondary);
}

.link-secondary-small:hover {
  color: var(--fs-color-link-primary);
}

.link-secondary-large {
  font-family: var(--fs-font-display);
  font-size: 2.4rem;
  text-decoration-line: underline;
  color: var(--fs-color-link-light);
}

.link-secondary-large:hover {
  color: var(--fs-color-link-secondary);
  /* color: var(--fs-color-font-dark); */
}


/* Link Button */
.link-button {
  display: flex;
  justify-content: center;
  align-items: end;
  
  width: 100%;
  padding-bottom: 3rem;
  padding-top: 3rem;

  

  font-family: var(--fs-font-display);
  font-size: 2.6rem;
  text-decoration-line: underline;
  
  background: var(--fs-color-font-primary);
  color: var(--fs-color-font-light);
  stroke: var(--fs-color-font-light);
}

@media (min-width: 768px) {
  .link-button {
    padding-bottom: 9rem;
    padding-top: 9rem;
  }
}

.link-button:hover {
  background: var(--fs-color-secondary);
  color: var(--fs-color-font-dark);
  stroke: var(--fs-color-font-dark);
}

.link-button-icon {
  margin-right: 2rem;
  margin-bottom: 0.15rem;
  height: 2.6rem;
  /* height: 3.5rem; */
}


/* Link Mail Address */
.link-mail {
  display: block;
  width: 26rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;

  font-family: var(--fs-font-display);
  font-size: 2rem;

  text-decoration-line: underline;
  text-transform: lowercase;

  color: var(--fs-color-font-light);
background: var(--fs-color-font-primary);
}

.link-mail:hover {
  background: var(--fs-color-secondary);
  color: var(--fs-color-font-dark);
  /* box-shadow: var(--fs-button-shadow-small); */
}



/* Link Light */
/* .link-light-small {
  font-size: 2rem;
  line-height: 3rem;
  text-decoration-line: underline;
  color: var(--fs-color-link-light);
}

.link-light-small:hover {
  color: var(--fs-color-link-primary);
} */

/* @media (min-width: 1024px) {
  .link-light-small {
    font-size: 1.5rem;
  }
} */

/* .link-light-medium {
  font-style: italic;
  font-size: 2rem;
  line-height: 4rem;
  letter-spacing: 0.2em;
  text-decoration-line: underline;
  color: var(--fs-color-link-light);
}

.link-light-medium:hover {
  text-shadow: -2px 2px 4px rgba(0, 0, 0, 0.7);
} */

/* .link-light-large {
  font-family: var(--fs-font-display);
  font-style: italic;
  font-size: 2.5rem;
  line-height: 6rem;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  text-transform: lowercase;
  color: var(--fs-color-link-light);
}

.link-light-large:hover {
  text-shadow: -2px 2px 4px rgba(0, 0, 0, 0.7);
} */

/* Link Primary */


/* .link-primary-large {
  font-family: var(--fs-font-display);
  font-style: italic;
  font-size: 2.5rem;
  line-height: 6rem;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  text-transform: lowercase;
  color: var(--fs-color-link-primary);
}

.link-primary-large:hover {
  color: var(--fs-color-light-gray);
} */

/* Link Secondary */